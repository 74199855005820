<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 评价晒单
 * @Date: 2021-01-06 16:58:16
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-06 20:08:26
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/evaluate.vue
-->
<template>
  <div class="user_content">
    <div class="evalute_info">
      <div class="info_img">
        <img :src="info.goodCover[0]" alt="">
      </div>
      <div class="info_test">
        <div>{{ info.goodName }}</div>
        <div>数量：{{ info.goodNum }}个 规格：{{ info.goodSpec }}</div>
        <van-rate v-model="goods_score" />
      </div>
    </div>
    <div class="evalute_test">
      <div>分享你的使用体验吧</div>
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="从多个角度评价宝贝，可以帮助更多想买的人"
        show-word-limit
      />
      <div class="up_img">
        <van-uploader v-model="fileList" :after-read="afterRead" :max-count="3" />
      </div>
      <van-checkbox v-model="checked" checked-color="#ee0a24">匿名评价</van-checkbox>
    </div>
    <div class="evalute_stars">
      <div>
        <van-icon name="logistics" />
        <span style="margin-left: 10px;">物流服务评价</span></div>
      <div class="srars">
        <div class="srars_item">
          <div>快速包装</div>
          <van-rate v-model="lo_package_score" />
        </div>
        <div class="srars_item">
          <div>送货速度</div>
          <van-rate v-model="lo_distribution_score" />
        </div>
        <div class="srars_item">
          <div>配送员服务</div>
          <van-rate v-model="lo_courier_score" />
        </div>
      </div>
      <div class="Submit" @click="Submit">提交</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Field, Icon, Rate, Toast, Uploader } from 'vant'

Vue.use(Rate).use(Field).use(Uploader).use(Checkbox).use(Icon).use(Toast)
import {
  addComment
} from '@/services/userApi'

export default {
  data() {
    return {
      goods_score: 5,
      lo_package_score: 5,
      lo_courier_score: 5,
      lo_distribution_score: 5,
      message: '',
      fileList: [],
      checked: true,
      info: '',
      mid: ''
    }
  },
  created() {
    this.mid = localStorage.getItem('uid')
    this.info = this.$route.query.info
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
    },
    Submit() {
      const imgStr = ''
      if (this.fileList.length !== 0) {
        this.fileList.forEach(e => {

        })
      }

      const prom = {
        goods_score: this.goods_score,
        record_id: this.info.recordId,
        lo_package_score: this.lo_package_score,
        lo_courier_score: this.lo_courier_score,
        lo_distribution_score: this.lo_distribution_score,
        is_anonymous: this.checked ? 0 : 1,
        imgs: imgStr,
        product_id: this.info.productSkuId,
        mid: this.mid,
        sku_id: this.info.productSkuId,
        comment: this.message,
        type: 1
      }
      addComment(prom).then(res => {
        if (Number(res.code) === 200) {
          this.$router.push({ path: '/myorder' })
          Toast(res.msg)
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .evalute_info {
    padding: 13px;
    background: #fff;
    display: flex;

    .info_img {
      width: 93px;
      height: 93px;
      border-radius: 3px;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    .info_test {
      width: 80%;
      margin-left: 10px;
      text-align: left;

      & > div:nth-child(1) {
        line-height: 20px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      & > div:nth-child(2) {
        color: #666666;
        margin: 10px 0;
      }
    }
  }

  .evalute_test {
    padding: 13px;
    margin-top: 10px;
    background: #fff;

    & > div:nth-child(1) {
      text-align: left;
      font-size: 14px;
    }

    .up_img {
      padding: 13px;
      text-align: left;
    }
  }

  .evalute_stars {
    padding: 13px;
    margin-top: 10px;
    background: #fff;

    & > div:nth-child(1) {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .srars {
      margin: 13px;

      .srars_item {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
      }
    }

    .Submit {
      background: #E33F44;
      color: #fff;
      padding: 10px;
      margin-top: 40px;
      border-radius: 20px;
    }
  }
}
</style>
